import styled from 'styled-components'
import { useUser } from '../hooks/useUser'
import Profile from './profile'

export const UserProfile = () => {
  const user = useUser()
  return (
    <Container>
      <Profile user={user}/>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
`