import { atom } from 'jotai'
import { useDoc } from './useDoc'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js'
import { useAuthUser } from './useAuthUser.js'

export const employeeAtom = atom({})

export const useEmployee = () => {
  const { employeeId } = useParams()
  const [userEmail] = useAuthUser()
  return useDoc(`users/${employeeId || userEmail}`)
}