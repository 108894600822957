export const color1 = `#fafbfd`
export const color2 = `#2137d2`
export const color3 = `#ABF2D3`
export const color4 = `#63a3f7`
export const color5 = `#fafbfd`
export const color6 = `#2137d2`
export const keyColor = `#646464`
export const background = `#fafbfd`
export const darkblue = `#2137d2`
export const pink = `#d47cbf`
export const green = `#5bf9b5`
export const red = `#da6079`
export const orange = `#ef8d7b`
export const orangeBackground = `#fefaf9`
export const pinkBackground = `#fdf9fc`
export const blueBackground = `#f7faff`
export const lightblueBackground = `#cce2ff`
export const darkblueBackground = `#bdd3fa`
export const lightTurquoiseBackground = `#bee8e5`
export const lightYellowBackground = `#ffe880`
export const lightGreenBackground = `#b0e8ae`
export const purpleBackground = `#dbd4ff`
export const devModeColor = `#d47cbf`
export const testColor = `#7057de`
export const errorColor = `#ef5350`
export const lightgray = `#fafafa`
export const midgray = `#CECFD0`
export const completedColor = green
export const unreachableColor = `gray`
export const googleBlue = `#4285F4`

export const primary = `#E4003E`
export const secondary = `#FF003F`
export const tertiary = `#ffcd00`
export const white = `white`

export const one = `#303C49`
export const two = `#59636D`
export const three = `#838A92`
export const four = `#AFAFAF`
export const five = `#D7D8DB`
export const six = `#E1E3E7`
export const seven = `#F0F1F5`

export const lightpurple = five
export const darkpurple = four
export const darkgray = one
export const lightblue = four