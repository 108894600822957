import React,{ useState,useEffect } from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import { darkgray,green } from '../utils/colours'
import { useParams } from 'react-router-dom'
import Spinner from './spinner'
import toast from 'react-hot-toast'
import { successStyle,errorStyle } from '../utils/toast.format'
import ProjectsTable from './projectsTable'
import Button from './button'
import { useHistory } from 'react-router-dom'
import { Form } from '@wakeflow/ui'
import { useCollection } from '../hooks/useCollection'
import { db } from '../Firebase'

const Client = () => {
  const clients = useCollection(`clients`)
  const projects = useCollection(`projects`)
  const { clientId } = useParams()
  const client = clients.find(c => c.id === clientId)
  const clientFields = [`name`,`number`,`vatPercentage`,`email`,`contactPhoneNumber`]
  const clientProjects = projects.filter(p => p.clientId === clientId)
  const history = useHistory()

  const getState = () => {
    const viewedClient = clients.find(u => u.id === clientId)
    if(!viewedClient) return {} 
    const initialState = {} 
    clientFields.forEach(key => initialState[key] = viewedClient[key])
    return initialState
  }

  const clientSchema = {
    type: `object`,
    properties: {
      name: { type: `string` },
      number: { type: `number` },
      vatPercentage: { 
        type: `percentage`,
        minimum: 0,
        maximum: 1,
        errorMessage: `Value needs to be between 0 and 100`,
      },
    },
  }

  const [state,setState] = useState(getState())

  useEffect(() => setState(getState()),[client])
  
  const handleSubmit = async() => {
    const updates = {}
    clientFields.forEach(key => {
      if(typeof state[key] != `undefined`) updates[key] = state[key]
    })
    await db.doc(`clients/${client.id}`).update(updates)
    toast(`Details updated`,successStyle)
  }

  const handleRemoveClient = async() => {
    try {
      let answer = confirm(`Are you sure you want to delete this client?`)
      if(answer) 
        await db.collection(`clients`).doc(client.id).delete()
      toast(`Succesfully removed client.`,successStyle)
      history.push(`/clients`)
    }
    catch(error){toast(`Failed to delete client.`,errorStyle)}
  }

  return (
    <MainLayout>
      <Container>
        {!client && <Spinner/>}
        {client && 
        <>
          <Form 
            values={state}
            setValues={setState}
            jsonSchema={clientSchema}
            css={`width:50%;`}/>
          <ButtonContainer>
            <Button onClick={handleSubmit}>Submit</Button>  
            <Button 
              onClick={handleRemoveClient} 
              css="margin-left: 20px">
              Delete Client
            </Button>      
          </ButtonContainer>
          <ProjectsTable projects={clientProjects}/>
        </>}
      </Container>
    </MainLayout>
  )
}

export default Client

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: clamp(250px,60vw,1000px);
  max-width: 1000px;
  color: ${darkgray};
  padding: 20px;
`
const Header = styled.h1`
  font-size: 3em;
  font-weight: 700;
  ::first-letter{
    color: ${green};
  }
`

const ButtonContainer = styled.div`
  padding: 20px;
  display: flex;
`