import { db,parseCollectionSnapshot } from '../Firebase'
import { useAtom } from 'jotai'
import { collectionsAtom,colSubscriptions } from './useCollection'


export const useHolidayTimes = (name = `holidays`) => {
  const [collections,setCollections] = useAtom(collectionsAtom)
  colSubscriptions[name] = db.collection(`times`)
    .where(`projectId`,`==`,`kFp9TSmXsXYKo5POMeaf`)
    .where(`hours`,`>`,0)
    .onSnapshot(snapshot => {
      setCollections(s => {
        console.log(`got snapshot`)
        s[name] = parseCollectionSnapshot(snapshot)
        return s
      })
    })
  return collections[name] || []
}