import React from 'react'
import styled from 'styled-components'
import DayRow from './dayRow'
import {
  blueBackground,darkgray,five,four,lightblue,lightblueBackground,seven,six, 
} from '../utils/colours'
import moment from 'moment'
import { sortArrayAlphabeticallyByName } from '../utils/sort'
import { useTimes } from '../hooks/useTimes'
import { useMonth } from '../hooks/useMonth'
import { useSelectedProjects } from '../hooks/useSelectedProjects'
import { useUser } from '../hooks/useUser'

const Calendar = () => {
  const user = useUser()
  const times = useTimes()
  const [selectedProjects] = useSelectedProjects()
  const [month] = useMonth()
  const startDay = moment.utc(month).clone().startOf(`month`)
  const endDay = moment.utc(month).clone().endOf(`month`)
  let date = startDay.clone().subtract(1,`day`)
  const calendar = []

  while (date.isBefore(endDay,`day`)) calendar.push(date.add(1,`day`).clone())

  const userTimes = times.filter(time => {
    if(!user.email) return []
    return time.email === user.email 
    && moment(time.date).format(`YYYY-MM`) === moment(month).format(`YYYY-MM`)
  })

  return (
    <Container>
      <Table>
        <THead>
          <Tr>
            <ThD> Date </ThD>
            {sortArrayAlphabeticallyByName(selectedProjects).map((selected,index) => {
              return(
                <Th key={index}> {selected.name} </Th>
              )
            })}
            <Th> Total </Th>
          </Tr>
        </THead>
        <TBody>
          {calendar.map((day,index) => <DayRow day={day} userTimes={userTimes} key={index}/>)}
        </TBody>
      </Table>
    </Container>
  )
}

export default Calendar

const Container = styled.div`
  width: clamp(300px,85vw,1000px);
`
const Table = styled.table`
  display: table;
  width: 100%;
  border-collapse: collapse;
  color: ${darkgray};
  @media screen and (max-width: 600px){
    overflow-y:scroll;
    height: 50vh;
    display:block;
  }
  
`
const THead = styled.thead`
  background-color: ${five};
`
const ThD = styled.th`
  width: 25%;
`
const Th = styled.th`
`
const Tr = styled.tr`
  font-size: 1.2em;
  height: 2rem;
`
const TBody = styled.tbody`
  tr:nth-child(even){
    background-color: ${seven};
    &:hover{
      background-color: ${four};
    }
  }
`