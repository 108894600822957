import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { lightblue,midgray,primary,purpleBackground } from '../utils/colours'
import { formatDay } from '../utils/formatter'
import HourInput from './hourInput'
import { sortArrayAlphabeticallyByName } from '../utils/sort'
import { useSelectedProjects } from '../hooks/useSelectedProjects'
import { useTimes } from '../hooks/useTimes'
import { useUser } from '../hooks/useUser'

const DayRow = ({ day }) => {
  const user = useUser()
  const [selectedProjects] = useSelectedProjects()
  const times = useTimes()
  if(!user.id) return null
  const userTime = times.filter(time => {
    return time.email === user.email
    && moment(time.date).format(`YYYY-MM-Do`) === moment(day).format(`YYYY-MM-Do`)
  })

  let total = 0
  if(userTime.length > 0) 
    userTime.forEach(time => {
      total += time.hours
    })
  let today = moment(day).isSame(moment(),`day`)
  let weekend = (moment(day).isoWeekday() === 6 || moment(day).isoWeekday() === 7)
  return (
    <Tr today={today} weekend={weekend}>
      <Td>{formatDay(day)}</Td >
      {sortArrayAlphabeticallyByName(selectedProjects).map((selected,index) => {
        return( 
          <Td key={index}><HourInput project={selected} day={day} /></Td>
        )
      })}
      <Td>{total}</Td>
    </Tr>
  )
}

export default DayRow

const Tr = styled.tr`
  background-color: ${props => props.weekend ? `${midgray} !important` : ``};
  background-color: ${props => props.today ? `${primary} !important` : ``};
  height: 3vh;
  &:hover{
    background-color: ${lightblue} !important;
  }
`
const Td = styled.td`
  text-align: center;
  font-size: 1em;
  font-weight: 700;
  
`