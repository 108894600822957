import React,{ useRef } from 'react'
import styled from 'styled-components'
import Button from './button'

const ImageUploader = ({ label,value,...otherProps }) => {
  const handleClick = () => {
    ref.current.click()
  }  
  const ref = useRef()
  return (
    <Container>
      <Button onClick={handleClick}>{label}</Button>
      <StyledInput
        ref={ref}
        name="Upload Profile Photo"
        type="file"
        accept="image/*"
        hidden
        {...otherProps}/>
    </Container>
  )
}

export default ImageUploader

const Container = styled.div `
`
const StyledInput = styled.input`
`