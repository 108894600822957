import React from 'react'
import styled from 'styled-components'
import { darkgray } from '../utils/colours'
import MainLayout from './mainLayout'
import UserGoals from './userGoals'
import ArchivedGoals from './archivedGoals'
import Button from './button'
import toast from 'react-hot-toast'
import { successStyle } from '../utils/toast.format'
import moment from 'moment'
import { useUser } from '../hooks/useUser'
import { db } from '../Firebase'

const Goals = () => {
  const user = useUser()

  const handleClick = async() => {
    const newGoal = {
      createdAt: moment.utc().toDate(),
      completedAt: ``,
      text: `New Goal!`,
      status: `pending`,
      userId: user.id,
    }
    const goalRef = db.collection(`goals`).doc()
    await goalRef.set(newGoal)
    toast(`Goal added.`,successStyle)
  }
  

  return (
    <MainLayout>
      <Container>
        <Button onClick={handleClick}>+ Add Goal</Button>
        <UserGoals/>
        <ArchivedGoals/>
      </Container>
    </MainLayout>
  )
}

export default Goals

const Container = styled.div`
  color: ${darkgray};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  padding: 20px;
  gap: 20px;
`
