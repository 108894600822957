import React from 'react'
import styled from 'styled-components'
import Burger from './hamburgerButton'
import MobileSidebar from './MobileSideBar'
import { useState } from 'react'
import { Sidebar } from './Sidebar.js'

const MainLayout = ({ children }) => {
  const [openedSidebar,setOpenedSidebar] = useState(false)

  return (
    <Container>
      <BurgerContainer>
        <Burger opened={openedSidebar} setOpened={setOpenedSidebar}/>
      </BurgerContainer> 
      <MobileSidebar opened={openedSidebar} setOpened={setOpenedSidebar}/> 
      <Sidebar />
      <NotSidebar>
        <Content>
          {children}
        </Content>
      </NotSidebar>
    </Container>
  )
}

export default MainLayout

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction:wrap;
  min-height:100vh;
  min-width: 100vw;
`
const Content = styled.div`
  display:flex;
  flex-direction:column;
  flex: 1;
  margin-bottom:100px;
  justify-content:center;
  align-items:center;
  /* margin-top: 5rem; */
  overflow-y:scroll;
  /* max-height:100vh; */
`
const BurgerContainer = styled.div`
  z-index: 200;
  position: fixed;
  top: 1rem;
  left: 1rem;
  @media screen and (min-width: 768px) {
  display: none;
}
`

const NotSidebar = styled.div`
 @media screen and (max-width: 768px) {
  margin-left: 0;
  }
  margin-left: 20rem;
  width: 100%;
  height: 100vh;
`