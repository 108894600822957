import React from 'react'
import styled from 'styled-components'
import Goal from './goal'
import ProfilePicture from './profilePicture'
import { useCollection } from '../hooks/useCollection'
import { useUser } from '../hooks/useUser'

const UserGoals = () => {
  const user = useUser()

  //TODO
  const person = user

  const goals = useCollection(`goals`).filter(g => g.userId === person.id)
  const completed = goals.filter(pg => pg.status !== `completed`)
  return (
    <Container>
      <ProfilePictureContainer>
        <ProfilePicture profilePhoto={person?.profilePhoto}/>
      </ProfilePictureContainer>
      <GoalList>
        {completed.length <= 0 && <div>No goals! 😓</div>}
        {completed && completed.map((goal,index) => {
          return <Goal goal={goal} key={index} index={index} />
        })}
      </GoalList>
    </Container>
  )
}

export default UserGoals

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  width: 80 vw;
  background-color: rgba(239,141,123,0.2);
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 1rem;
  border-radius: 20px;
  box-shadow:
  2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
  6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
  12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
  22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
  41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
  100px 100px 80px rgba(0, 0, 0, 0.07)
;
`
const ProfilePictureContainer = styled.div`
  /* background-color: #ff0000; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  border: 6px solid rgb(239,141,123);
  & img {
   border-radius: 100%;
  }
`
const GoalList = styled.div`
  display: flex;
  font-size: clamp(2rem,18px,36px);
  flex-direction: column;
  width: clamp(200px,40vw,700px);
  padding-left: 10px;
  @media screen and (max-width: 600px){
    gap:10px;
  }

`