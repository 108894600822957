import React from 'react'
import styled from 'styled-components'
import ArchivedGoalsList from './archivedGoalsList'
import { useUser } from '../hooks/useUser'
import { useCollection } from '../hooks/useCollection'

const ArchivedGoals = () => {
  const user = useUser()
  const person = user
  const goals = useCollection(`goals`).filter(g => g.userId === person.id)
  const completedGoals = goals.filter(g => { return (g.status === `completed`)})

  return (
    <Container>
      <ContentContainer>
        <Header><p>Completed Goals</p></Header>
        {completedGoals.length === 0 && <div>No completed Goals 😥</div>}
        {completedGoals.length !== 0 && <ArchivedGoalsList completedGoals={completedGoals} />}
      </ContentContainer>
    </Container>
  )
}

export default ArchivedGoals

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: clamp(250px,30vw,700px);
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
`
const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1.5em;
  font-weight: 700;
  & p {
    margin: 0;
  }

`