import React from 'react'
import MainLayout from './mainLayout'
import { OnboardingItem } from './onboardingItem'
import styled from 'styled-components'

export const Onboarding = () => {

  const tasks = [
    {
      id: 1,
      label: `set up on Slack`,
    },
    {
      id: 2,
      label: `buddy assigned`,
    },
    {
      id: 3,
      label: `linear walk-through`,
    },
    {
      id: 4,
      label: `please read: `,
      link: `https://github.com/wakeflow/how-we-do/blob/master/docs/holidays.md`,
    },
    {
      id: 5,
      label: `please read: `,
      link: `https://github.com/wakeflow/how-we-do/blob/master/docs/good_software.md`,
    },
    {
      id: 6,
      label: `please read: `,
      link: `https://github.com/wakeflow/how-we-do/blob/master/docs/progression.md`,
    },
    {
      id: 7,
      label: `time keeping explained on `,
      link: `https://team.wakeflow.io/today`,
    },
    {
      id: 8,
      label: `cicd with github actions explained`,
    },
    {
      id: 9,
      label: `added to payroll OR invoicing explained`,
    },
    {
      id: 10,
      label: `profile filled in on `,
      link: `https://team.wakeflow.io/profile`,
    },
    {
      id: 11,
      label: `profile pic added on `,
      link: `https://team.wakeflow.io/profile`,
    },
    {
      id: 12,
      label: `profile added on `,
      link: `wakeflow.io/team`,
    },
    {
      id: 13,
      label: `added to github org on `,
      link: `https://github.com/orgs/wakeflow/people`,
    },
    {
      id: 14,
      label: `added to LinkedIn org on `,
      link: `https://www.linkedin.com/company/wakeflow/people/?viewAsMember=true`,
    },
    {
      id: 15,
      label: `please browse at leisure (no need to read cover to cover) `,
      link: `https://wakeflow.github.io/how-we-do/`,
    },
    {
      id: 16,
      label: `bi-weekly check-in set up?`,
    },
    {
      id: 17,
      label: `@wakeflow.io email added to github profile: `,
      link: `https://github.com/settings/emails`,
    },
    {
      id: 18,
      label: `added to daily standup calendar invite`,
    },
    {
      id: 19,
      label: `added to holiday calendar`,
    },
    {
      id: 20,
      label: `added to team@wakeflow.io email group`,
    },
    {
      id: 21,
      label: `Intro meetings with every team member scheduled`,
    },
    {
      id: 22,
      label: `Discuss how we do github with Andi`,
    },
  ]

  return(
    <MainLayout>
      <Header>Onboarding Checklist</Header>
      <Container>
        {tasks.map(task => {
          return(
            <OnboardingItem key={task.id} task={task}/>
          )
        })}
      </Container>
    </MainLayout>
  ) 
}

const Container = styled.div`
  display:flex;
  align-items:flex-start;
  margin:2vw 10vw;
  gap:10px;
  flex-direction:column;
  overflow-y: scroll;
`
const Header = styled.h1`
  color:lightpurple;
`
