import React from 'react'
import styled from 'styled-components'
import { darkgray,darkpurple,lightblue,lightpurple,pink } from '../utils/colours'

const Wiki = () => {
  return (
    <Container>
      <Header>Welcome!</Header>
      <WordContainer>Here are some useful and commonly used links at Wakeflow.</WordContainer>
      <Ul>
        <A target="_blank" rel="noreferrer noopener" href="https://wakeflow.io/"><Li>Our home</Li></A>
        <A target="_blank" rel="noreferrer noopener" href="https://github.com/wakeflow/how-we-do"><Li>How we do</Li></A>
        <A target="_blank" rel="noreferrer noopener" href="https://github.com/wakeflow/dotfiles"><Li>Dotfiles</Li></A>
      </Ul> 
    </Container>
  )
}

export default Wiki

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`
const Header = styled.h1`
  text-align: center;
  font-size: 3em;
  font-weight: 700;
  color: ${darkgray};
  span{
    color:${darkpurple};
  }
`
const WordContainer = styled.div``
const Li = styled.li`

  border-radius:4px;
  list-style-type: none;
  padding:5px;
  &:hover{
    background-color: red;
  }
`
const Ul = styled.ul`
  margin-top: 10px;
  padding-left: 2rem;
  padding-left: 2rem;
  list-style-type: circle;
  li:nth-child(even) {
    &:hover{
    background-color: ${lightpurple};
    }
  }
  li:nth-child(odd) {
    &:hover{
    background-color: ${lightblue};
    }
  }

`
const A = styled.a`
  &:visited{
    text-decoration: none;
    color: ${darkgray}
  }
  &:active{
    text-decoration: none;
    color: ${darkgray}
  }
  &:link{
    text-decoration: none;
    color: ${darkgray}
  }
`