import React from 'react'
import { darkgray,lightpurple,one } from '../utils/colours'
import { Button as WakeflowButton } from '@wakeflow/ui'
import styled from 'styled-components'

const Button = ({ onClick,children,styles,css }) => {
  const defaultCss = `
    border:none;
    display:flex;
    justify-content:center;
    color:white;
    font-size: 1.1rem;
    box-sizing:border-box;
    background-color: ${styles ? styles : one};
    padding: 10px 20px;
    cursor: pointer;
    border-radius:4px;
    transition: all .2s ease-in-out;
    &:hover{
    background-color: ${lightpurple};
    transform: scale(1.1);
    }
    outline:none;
    @media screen and (max-width:600px){
      padding: 0.4em 0.7em;
    }

    button {
      background: transparent;
      box-shadow:0px 0px 0px 0px;
      padding:0px;

      &:active {
        border:none;
      }
    }
  `
  return (
    <StyledButton 
      css={`
        ${defaultCss}
        ${css}
      `}
      onClick={onClick}>
      {children}
    </StyledButton>
  )
}

export default Button

const StyledButton = styled.button`
  ${p => p.defaultCss}
  ${p => p.css}
`