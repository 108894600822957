import { db,parseDocSnapshot } from '../Firebase'
import { atom,useAtom } from 'jotai'
import _ from 'lodash'

const docsAtom = atom({})

export const docSubscriptions = {}

export const useDoc = path => {
  const [docs,setDocs] = useAtom(docsAtom)
  const [collection,id] = path.split(`/`)
  if(!collection || !id) return {}
  if(docSubscriptions[path]) return docs[path] || {}
  docSubscriptions[path] = db.collection(collection).doc(id).onSnapshot(snapshot => {
    setDocs(s => {
      const output = _.cloneDeep(s)
      output[path] = parseDocSnapshot(snapshot)
      return output
    })
  })
  return docs[path] || {}
}