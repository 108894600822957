import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Trash } from 'react-feather'
import { errorStyle,successStyle } from '../utils/toast.format'
import { toast } from 'react-hot-toast'
import { useCollection } from '../hooks/useCollection'
import { db } from '../Firebase'

const ProjectItem = ({ project }) => {
  const history = useHistory()
  const clients = useCollection(`clients`)
  const client = clients.find(c => c.id === project.clientId)

  const handleClick = () => {
    history.push(`/project/${project.id}`)
  }

  const onDelete = async(e,projectId) => {
    e.stopPropagation()
    try {
      let answer = confirm(`Are you sure you want to delete this project?`)
      if(answer) {
        await db.collection(`projects`).doc(projectId).delete()
        toast(`Succesfully removed project.`,successStyle)
        history.push(`/projects`)
      }
      else 
        return
    }
    catch(error) {toast(`Failed to delete project`,errorStyle)}
  }

  return (
    <Tr onClick={handleClick}>
      <Td> {project.name} </Td>
      <Td> {project?.clientName || client?.name} </Td>
      <Td> {project?.status} </Td>
      <Td onClick={e => onDelete(e,project.id)}>
        <Trash size="16" />
      </Td>
    </Tr>
  )
}

export default ProjectItem

const Td = styled.td`
  text-align: center;
  padding:10px;
  border-radius:0;
`
const Tr = styled.tr`
  :hover{
    cursor: pointer;
  }
`
