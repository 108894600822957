import React from 'react'
import { useUser } from '../hooks/useUser'
import MainLayout from './mainLayout'
import styled from 'styled-components'
import Button from './button'
import Wiki from './wiki'

const Landing = () => {
  const user = useUser()
  
  return (
    <MainLayout>
      <Container>
        <Img src="/wakeflow.png"/>
        {!user.email && <ButtonContainer><Button style={{ width: `10vw` }}>Login</Button></ButtonContainer>}
        {user.email && <Wiki />}
      </Container>
    </MainLayout>
  )
}

export default Landing

const Container = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding: 20px;
`
const Img = styled.img`
  height:70px;
`
const ButtonContainer = styled.div `
  width:10vw;
  border-radius: 4px;
`