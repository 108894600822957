import React from 'react'
import styled from 'styled-components'
import { errorColor } from '../utils/colours'

const ErrorBoundaryMessage = () => {
  return (
    <Container>
      <Img src="/wakeflow.png"/>
      <Title>Sorry, there has been an error</Title>
      <Subtitle>Please contact support on support@wakeflow.io. Thanks</Subtitle>
    </Container>
  )
}

export default ErrorBoundaryMessage 

const Container = styled.div`
  display:flex;
  padding:30px;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  width:100%;
`

const Title = styled.h1`
  text-align:center;
  font-size:2rem;
  font-weight:200;
  color:${errorColor};
`
const Subtitle = styled(Title)`
  font-size:1.5rem;
  font-weight:200;
  color:gray;
`
const Img = styled.img`
  max-width:500px;
`