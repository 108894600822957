import React from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import { darkgray,lightblue,lightgray,lightpurple } from '../utils/colours'
import 'firebase/auth'
import ClientItem from './clientItem'
import { useHistory } from 'react-router-dom'
import Button from './button'
import { useCollection } from '../hooks/useCollection'
import { db } from '../Firebase'

const Clients = () => {
  const clients = useCollection(`clients`)
  const history = useHistory()
  const handleClick = async() => {
    const newClient = {
      name: `New Client`,
      number: 0,
      vatPercentage: 0,
    }
    const clientRef = db.collection(`clients`).doc()
    await clientRef.set(newClient)
    history.push(`/client/${clientRef.id}`)
  }  
  return (
    <MainLayout>
      <Container>
        <Table>
          <thead>
            <tr>
              <Th css="text-align:left;"> Name </Th>
              <Th css="text-align:right;"> Client Number </Th>
              <Th></Th>
            </tr>
          </thead>
          <Tbody>
            {clients.map((client,index) => {
              return(
                <ClientItem client={client} key={index}/>
              )
            })}
          </Tbody>
        </Table>
        <Button 
          css={`margin-top:10px;`} 
          onClick={() => handleClick()}>
          + Client
        </Button>
      </Container>
    </MainLayout>
  )
}

export default Clients

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  color: ${darkgray};
  padding: 20px;
`
const Table = styled.table`
  display: table;
  flex-grow: 1;
  border-collapse: collapse;
  width: 60%;
  @media screen and (max-width: 600px){
    display: block;
    overflow-x: auto;
    width: 80%;
  }
`
const Th = styled.th`
  font-weight: 500;
  padding:20px;
  border-radius:0;
  text-align: center;
  border-bottom: 2px solid ${darkgray};
  ${p => p.css}
`
const Tbody = styled.tbody`
`