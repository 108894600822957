import React from 'react'
import styled from 'styled-components'
import { blueBackground,pink,primary,purpleBackground } from '../utils/colours'

const Spinner = ({ size = 16 }) => {
  return (
    <Container size={size}>
      <SpinnerImage className="spinner"/>
    </Container>
  )
}

export default Spinner 

const Container = styled.div`
  display:flex;

  .spinner {
    margin:${p => p.size}px;
    border: ${p => p.size / 2}px solid black; 
    border-top: ${p => p.size / 2}px solid ${primary}; 
    border-radius: 50%;
    width: ${p => p.size * 5}px;
    height: ${p => p.size * 5}px;
    animation: spin 2s linear infinite ;
    animation-fill-mode:both;
  }

  @keyframes spin {
    0% { 
      transform: rotate(0deg); 
      /* width:150px; */
      /* height:150px; */
    }
    50% { 
      transform: rotate(180deg); 
      /* width:110px; */
      /* height:110px; */
    }
    100% { 
      transform: rotate(360deg); 
      /* width:150px; */
      /* height:150px; */
    }
  }
`
const SpinnerImage = styled.div`
  flex-grow:0;
`

