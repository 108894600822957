import React from 'react'
import styled from 'styled-components'
import LoginButton from './LoginButton.js'

const Login = () => {
  return(
    <Container>
      <Box>
        <Img src="/wakeflow.png"/>
        <LoginButton />
      </Box>
    </Container>
  )
}

export default Login

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`
const Img = styled.img`
  height:70px;
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width:360px;
  max-width:30vw;
  padding: 20px 20px 20px 20px;
  gap: 20px;
`