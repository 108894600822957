import './styles.css'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryMessage from './components/errorBoundaryMessage'
import Router from './route/router'
import { Toaster } from 'react-hot-toast'
const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryMessage}>
      <BrowserRouter>
        <Toaster position="top-center" toastOptions={{ duration: 3000 }}/>
        <Router/>
      </BrowserRouter>
    </ErrorBoundary>  
  )
}

export default App
