import { db,parseCollectionSnapshot } from '../Firebase'
import { useAtom } from 'jotai'
import { collectionsAtom,colSubscriptions } from './useCollection'

export const useTimes = (name = `times`) => {
  const [collections,setCollections] = useAtom(collectionsAtom)
  if(!name) return []
  if(colSubscriptions[name]) 
    return collections[name] || []
  colSubscriptions[name] = db.collection(name)
    .orderBy(`date`,`desc`)
    .limit(5 * 60 * 2)
    .onSnapshot(snapshot => {
      console.log(`---GOT COLLECTION SNAPSHOT---`)
      setCollections(s => {
        const output = _.cloneDeep(s)
        output[name] = parseCollectionSnapshot(snapshot)
        return output
      })
    })
  return collections[name] || []
}