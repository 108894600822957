import React,{ useState,useEffect } from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import { darkgray } from '../utils/colours'
import ProfilePicture from './profilePicture'
import toast from 'react-hot-toast'
import { errorStyle,successStyle } from '../utils/toast.format'
import Button from './button'
import HolidaysRemaining from './HolidaysRemaining'
import { Form } from '@wakeflow/ui'
import { db } from '../Firebase'

const Profile = ({ user }) => {
  const [state,setState] = useState({})

  useEffect(() => setState(user),[user])
 
  const handleSubmit = async() => {
    try{
      await db.doc(`users/${user.id}`).update({ ...state })
      toast(`Details updated`,successStyle)}
    catch(error){
      console.log(error)
      toast(`Details failed to upload`,errorStyle)
    }
  }

  const handleUpload = async profileImageName => {
    setState(s => ({ ...s,profilePhoto: profileImageName }))
    const update = { profilePhoto: profileImageName }
    await db.doc(`users/${user.id}`).update(update)
  }

  const jsonSchema = {
    type: `object`,
    properties: {
      firstName: { type: `string` },
      lastName: { type: `string` },
      email: { type: `string` },
      birthday: { type: `date` },
      phoneNumber: { type: `string` },
      address1: { type: `string` },
      address2: { type: `string` },
      city: { type: `string` },
      postCode: { type: `string` },
      country: { type: `string` },
      githubUsername: { type: `string` },
      linkedIn: { type: `string` },
      startDate: { type: `date` },
      leaveDate: { type: `date` },
      bankAccount: { type: `string` },
      sortCode: { type: `string` },
      iban: { type: `string` },
      taxCode: { type: `string` },
      biography: { type: `string` },
      emergencyContactContact: { type: `string` },
      emergencyContactName: { type: `string` },
      emergencyContactRelationship: { type: `string` },
    },
  }

  return (
    <MainLayout>
      <Container>
        <Inner>
          <Form
            values={state}
            setValues={setState}
            jsonSchema={jsonSchema}
            css={`input {width:100%};`}/>
          <Button
            onClick={handleSubmit}
            css={`margin-top:10px;`}>Submit</Button>
        </Inner>
        <Right className="right">
          <Picture>
            <ProfilePicture profilePhoto={user?.profilePhoto || ``} onUpload={handleUpload} />
            <HolidaysRemaining />
          </Picture>
        </Right>
      </Container>
    </MainLayout>
  )
}

export default Profile

const Container = styled.div`
  box-sizing:border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  color: ${darkgray};
  padding: 2em 0;
  height: 100vh;
  & input, textarea {
    border-radius: 20px;
    transition: all 100ms ease;
    font-size: 13px;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    height: 100%;
    overflow-y: scroll;
  }
  `
const Inner = styled.div`
  height: 100%;
  padding: 2rem;
  flex:1;
  justify-content: center;
  align-items:center;
  margin-bottom:2rem;
  @media screen and (max-width: 1200px) {
  padding: 0;
    width: 100%;
    overflow-y: unset;
  }
`
const Right = styled.div`
  display: flex;
  flex:1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 6rem;
  padding: 2rem 0;
  width: 100%;
`
const Picture = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 240px;
  height:100%;
  padding: 10px;
  gap: 4rem;
`