import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import Spinner from './spinner'
import ImageUploader from './imageUploader'
import toast from 'react-hot-toast'
import { errorStyle,successStyle } from '../utils/toast.format'
import { storage } from '../Firebase'

const ProfilePicture = ({ profilePhoto,onUpload }) => {
  const storageRef = storage.ref()
  const [imageUrl,setImageUrl] = useState(``)

  useEffect(async() => {
    try{
      if(!profilePhoto) return setImageUrl(`https://robohash.org/${Math.floor(Math.random() * 20) + 1}?200x200`)
      const pathRef = storageRef.child(`${profilePhoto}`)
      const url = await pathRef.getDownloadURL()
      setImageUrl(url)
    }
    catch(error){
      toast(`Something went wrong.`,errorStyle)
    }
  },[profilePhoto])

  const handleImageUpload = async imageInput => {
    try{
      const path = imageInput.target.value
      const imageName = path.substring(path.lastIndexOf(`\\`) + 1)
      const imageRef = storageRef.child(imageName)
      await imageRef.put(imageInput.target.files[0])
      onUpload(imageName)
      toast(`Image uploaded!`,successStyle)
    }
    catch(error){
      toast(`Image failed to upload`,errorStyle)
    }
  }

  return (
    <Container>
      {!imageUrl && <Spinner />}
      {imageUrl && <Img alt="profile" src={`${imageUrl}`}/>}
      {onUpload && <ImageUploader 
        label="Upload Profile Photo"
        onInput={i => handleImageUpload(i)}/>}
    </Container>
  )
}

export default ProfilePicture

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  flex-grow: 0 !important;
  gap:10px;
  width: 100%;
`
const Img = styled.img`
  height: 240px;
  width: 240px;
  object-fit: cover;
  border-radius: 50%;
`