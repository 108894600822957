import React,{ useRef,useState } from 'react'
import styled from 'styled-components'
import { lightpurple,darkgray } from '../utils/colours'

const Search = ({ onChange,style,backgroundColor,textColor }) => {
  const [value,setValue] = useState(``)
  const ref = useRef()

  const handleChange = e => {
    const { value } = e.target
    setValue(value.toLowerCase())
    onChange(value.toLowerCase())    
  }

  return (
    <Container style={style}>
      <Input
        // backgroundColor={backgroundColor}
        textColor={textColor}
        ref={ref}
        placeholder="Search..."
        value={value} 
        onChange={e => handleChange(e)}/> 
    </Container>
  )
}

export default Search 

const Container = styled.div`
  width: 50%;
  display:flex;
  flex-grow: 0;
  @media only screen and (max-width: 600px){
    justify-content: center !important;
  }
`
const Input = styled.input`
  width: 100%;
  border-radius:40px;
  padding: 0.75rem 1rem;
  margin:10px;
  border: 1px solid #949494;
  background-color: ${p => p.backgroundColor ? p.backgroundColor : `white`};

  &:hover {
    /* background-color: #dadada19; */
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  }
  &:focus {
    /* background-color: #dadada19; */
    outline: none;
    border: 1px solid ${darkgray};
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  }
  color: ${p => p.textColor ? p.textColor : `black`};
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${p => p.textColor ? p.textColor : `black`};
    opacity: 0.5; /* Firefox */
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:${p => p.textColor ? p.textColor : `black`};
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: ${p => p.textColor ? p.textColor : `black`};
  }
`

