import React from 'react'
import styled from 'styled-components'
import { darkpurple } from '../utils/colours'
import { formatBirthday } from '../utils/formatter'
const Input = ({ label,value,onChange,type,...otherProps }) => {
  
  const handleChange = value => {
    if(type === `percentage`)
      value = Number(value.replace(/[^0-9]/g,``).replace(/%/g,``)) / 100
    onChange(value)
  }

  let valueToShow = value
  if(type === `percentage`) valueToShow = `${(value || 0) * 100}%`
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <StyledInput
        type={type}
        value={valueToShow || ``}
        placeholder={label}
        onChange={e => handleChange(e.target.value)}
        {...otherProps}/>
    </Container>
  )
}

export default Input

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width:100%;
`
const Label = styled.div`
  display: flex;
  justify-content:flex-start;
  text-align:left;
  align-items: center;
  font-weight:600;
  /* margin-righ;t: 3vw; */
  flex:1 ; 
`
const StyledInput = styled.input`
  flex:2;
  padding:10px;
  box-sizing:border-box;
  background-color:white;
  border:thin solid lightgray;
  border-radius:40px;
  margin:5px;
  text-align: center;
  &:hover {
    /* background-color: #dadada19; */
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  }
  &:focus {
    /* background-color: #dadada19; */
    outline: none;
    border: 1px solid darkgray;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  }
`