import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import toast from 'react-hot-toast'
import { errorStyle,successStyle } from '../utils/toast.format'
import Button from './button'
import { googleBlue } from '../utils/colours'

const provider = new firebase.auth.GoogleAuthProvider()
provider.addScope(`https://www.googleapis.com/auth/contacts.readonly`)
provider.setCustomParameters({ hd: `wakeflow.io` })

const LoginButton = () => {

  const handleSignIn = async() => {
    try{
      await firebase.auth().signInWithPopup(provider)
      toast(`Login success!`,successStyle)
    }
    catch(error){
      toast(error.message,errorStyle)
    }
  }

  return(
    <Button 
      style={{ backgroundColor: googleBlue }}
      onClick={handleSignIn}>Sign in with Google</Button>
  )
}

export default LoginButton
