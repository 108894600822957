import React,{ useState,useEffect } from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import { darkgray,pink } from '../utils/colours'
import { useParams } from 'react-router-dom'
import Spinner from './spinner'
import { successStyle } from '../utils/toast.format'
import toast from 'react-hot-toast'
import Button from './button'
import { Form } from '@wakeflow/ui'
import { useCollection } from '../hooks/useCollection'
import { db } from '../Firebase'

const Project = () => {
  const { projectId } = useParams()
  const projects = useCollection(`projects`)
  const project = projects.find(p => p.id === projectId)
  const clients = useCollection(`clients`)
  const projectFields = [`name`,`clientId`,`contact`,`status`]

  const getState = () => {
    const viewedProject = projects.find(u => u.id === projectId) 
    if(!viewedProject) return {}
    const initialState = {} 
    const clientId = viewedProject[`clientId`] 
    const clientName = viewedProject[`clientName`] || clients.find(item => item.id == viewedProject[`clientId`])?.name || ``
    projectFields.forEach(key => initialState[key] = viewedProject[key])
    const updatedState = { ...initialState,clientName,clientId }
    return updatedState
  }

  const projectSchema = {
    type: `object`,
    properties: {
      name: { type: `string` },
      clientName: { 
        type: `string`,
        enum: [...Object.values(clients).map(c => c.name),``],
      },
      status: { 
        type: `string`,
        enum: [`active`,`inactive`,`paused`,`closed`], 
        errorMessage: `Must be selected`,
      },
    },
  }
  
  const contactSchema = {
    type: `object`,
    properties: {
      name: { type: `string` },
      address: { type: `string` },
      email: { type: `string` },
    },
  }

  const [state,setState] = useState(getState())

  useEffect(() => setState(getState()),[project,clients])

  const handleSubmit = async() => {
    const updates = {}
    const clientName = state[`clientName`]
    projectFields.forEach(key => {
      if(typeof state[key] != `undefined`) updates[key] = state[key]
    })
    const finalUpdates = { ...updates,clientName }
    await db.doc(`projects/${project.id}`).update(finalUpdates)
    toast(`Details updated`,successStyle) 
  }

  const handleContactChange = change => {
    setState(s => ({ ...s,contact: { ...s.contact,...change } }))
  }

  return (
    <MainLayout>
      <Container>
        {!project && <Spinner/>}
        {project && 
        <>
          <Form 
            values={state} 
            setValues={setState}
            jsonSchema={projectSchema} />
          <HeaderTwo>Con<span>ta</span>ct</HeaderTwo>
          <Form 
            values={state.contact} 
            setValues={handleContactChange}
            jsonSchema={contactSchema} />
          <Button 
            css={`margin-top:10px;`} 
            onClick={handleSubmit}>
            Submit
          </Button>        
        </>}
      </Container>
    </MainLayout>
  )
}

export default Project

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  color: ${darkgray};
  padding: 20px;
`
const Header = styled.h1`
  font-size: 3em;
  font-weight: 700;
  ::first-letter{
    color: ${pink};
  }
`
const HeaderTwo = styled.h2`
  font-size: 1.2rem;
  font-weight: 700;
  span {
    color: ${pink};
  }
`