import React from 'react'
import ProjectsTable from './projectsTable'
import MainLayout from './mainLayout'
import { useCollection } from '../hooks/useCollection'

const Projects = () => {
  const projects = useCollection(`projects`)

  return(
    <MainLayout>
      <ProjectsTable projects={projects}/>
    </MainLayout>
  ) 
}

export default Projects
