import React from 'react'
import { Trash } from 'react-feather'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { errorStyle,successStyle } from '../utils/toast.format'
import { db } from '../Firebase'
import { pinkBackground } from '../utils/colours'

const ClientItem = ({ client }) => {
  const history = useHistory()
  
  const handleClick = () => {
    history.push(`/client/${client.id}`)
  }
 
  const onDelete = async(e,clientId) => {
    e.stopPropagation()
    try {
      let answer = confirm(`Are you sure you want to delete this client?`)
      if(answer) 
        await db.collection(`clients`).doc(clientId).delete()
      toast(`Succesfully removed client.`,successStyle)
      history.push(`/clients`)
    }
    catch(error){toast(`Failed to delete client.`,errorStyle)}     
  }
  

  return (
    <Tr onClick={handleClick}>
      <Td css="text-align:left;"> {client.name} </Td>
      <Td css="text-align:right;"> {client.number} </Td>
      <Td onClick={e => onDelete(e,client.id)}>
        <Trash size="16"/>
      </Td>
    </Tr>
  )
}

export default ClientItem

const Td = styled.td`
  text-align: center;
  padding:10px;
  border-radius:0;
  ${p => p.css}
`
const Tr = styled.tr`
    cursor: pointer;
  :hover{
    background-color:${pinkBackground}
  }
`