import React from 'react'
import styled from 'styled-components'
import Button from './button'
import { sortArrayAlphabeticallyByName } from '../utils/sort'
import { useCollection } from '../hooks/useCollection'
import { useSelectedProjects } from '../hooks/useSelectedProjects'

const ProjectPicker = () => {
  const projects = useCollection(`projects`)
  const filteredProjects = projects.filter(p => p.status === `active`)
  const [selectedProjects,setSelectedProjects] = useSelectedProjects()

  const handleClick = clickedProject => {
    let tempChosenProjects = [...selectedProjects]
    if(tempChosenProjects.includes(clickedProject)){
      const index = tempChosenProjects.indexOf(clickedProject)
      tempChosenProjects.splice(index,1)
    }
    else
      tempChosenProjects.push(clickedProject)
    setSelectedProjects([...tempChosenProjects])
  }

  return(
    <Container>
      {sortArrayAlphabeticallyByName(filteredProjects).map((project,index) => {
        return(
          <Button 
            key={index}
            active={selectedProjects.map(p => p.id).includes(project.id)}
            onClick={() => handleClick(project)}
            className={project.name}>
            {project.name}
          </Button>)
      })}
    </Container>
  )
}

export default ProjectPicker
const Container = styled.div`
    /* width: 80vw; */
    display: flex;
    flex-wrap: wrap;
    /* max-height: 10vh; */
    gap: 10px;
    padding: 5px;
    margin-bottom: 10px;
    overflow-x: auto;
`
