import React,{ useEffect,useState } from 'react'
import MainLayout from './mainLayout'
import axios from 'axios'
import styled from 'styled-components'

const Analytics = () => {
  const [value,setValue] = useState(``)

  useEffect(() => {
    axios({
      method: `get`,
      url: `https://europe-west3-wakeflow-team.cloudfunctions.net/api/sso_embed_url`,
    }).then(response => {
      setValue(response.data)
    }).catch(error => {console.log(error)})
  }
  ,[])

  // if(!value) return null
  return (
    <MainLayout>
      <Container>
        <iframe
          src={value}
          frameBorder="0"
          width="100%"
          height="100%"></iframe>
      </Container>
    </MainLayout>
  )
}

export default Analytics

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
`