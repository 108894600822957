import moment from 'moment'
import React,{ useState } from 'react'
import { Edit,ThumbsUp,Trash2 } from 'react-feather'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import { db } from '../Firebase'
import { errorStyle,successStyle } from '../utils/toast.format'
import Button from './button'
import Input from './input'
import Modal from './modal'

const Goal = ({ goal,index }) => {
  const [show,setShow] = useState(false)
  const [editGoal,setEditGoal] = useState(goal.text)

  const handleDeleteClick = async() => {
    try {
      await db.collection(`goals`).doc(goal.id).delete()
      toast(`Succesfully removed goal.`,successStyle)
    }
    catch(error){toast(`Failed to delete goal.`,errorStyle)}
  }
  const handleEditClick = async() => {
    try{
      await db.doc(`goals/${goal.id}`).update({ text: editGoal })
      setShow(false)
      toast(`Succesfully edited goal 🎉`,successStyle)
    }catch(error){
      toast(`Failed to edit`,errorStyle)
      setShow(false)
    }
    
  }
  const handleCompletedClick = async() => {
    try{
      await db.doc(`goals/${goal.id}`).update({ status: `completed`,completedAt: moment.utc().toDate() })
      toast(`Succesfully completed goal 🎉`,successStyle)
    } catch(error){
      toast(`Failed to complete goal.`,errorStyle)
    }
  }

  return (
    <Container>
      <GoalNumber>{index + 1}</GoalNumber>
      <GoalText>{goal.text}</GoalText>
      <ButtonContainer>
        <Icon onClick={handleDeleteClick}><Trash2 size={`1rem`}/></Icon>
        <Icon onClick={() => setShow(true)}><Edit size={`1rem`}/></Icon>
        <Icon onClick={handleCompletedClick}><ThumbsUp size={`1rem`}/></Icon>
      </ButtonContainer>
      <Modal 
        show={show}
        close={() => setShow(false)}>
        <ModalContainer>
          <span>Update your goal!</span>
          <InputContainer> 
            <Input 
              type="text"
              value={editGoal}
              onChange={v => setEditGoal(v)}
              style={{ height: `3vh`,width: `5vw` }}/>
          </InputContainer>
          <Button onClick={handleEditClick}>Submit</Button>
        </ModalContainer>
      </Modal>

    </Container>
  )
}

export default Goal

const Container = styled.div`
  display: flex;
  max-height: 33%;
  padding: 5px 20px;
  border-radius: 7px;
  transition: 0.1s all;
  &:hover {
    background-color: rgb(239,141,123);
  }
`
const GoalNumber = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size:clamp(1rem,1.5rem,2rem);
  width: 1rem;
  flex: 0;
`
const GoalText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  width: 50vw;
  font-size: clamp(0.5rem,1rem,2rem);
  padding-left: 10px;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  align-items: center;
  align-content: center;
  width: clamp(100px,10vw,12vw);
  flex: 0;
`
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const Icon = styled.div`
 display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 5px;
`