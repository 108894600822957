import React from 'react'
import styled from 'styled-components'
import { routes } from '../config'
import { useUser } from '../hooks/useUser'
import { lightblue,lightpurple } from '../utils/colours'
import { useHistory } from 'react-router-dom'
import { Drawer } from '@mantine/core'


function Sidebar({ opened,setOpened }) {
  const user = useUser()
  const history = useHistory()

  const options = routes
    .filter(r => {
      if(user.email && r.route === `/login`) return false
      return true
    })
    .filter(r => {
      if(!user.email && r.route === `/logout`) return false
      return true
    })

  const handleClick = route => {
    history.push(route)
  }

  return (
    <Container>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        size="sm"
        withCloseButton={false}
        styles={{ background: `#363636` }}  
        overlayOpacity={0.5}>
        <Navigation>
          {options
            .filter(item => !item.requiresLogin || user.email)
            .map(({ Icon,route,label },index) => {
              return(
                <NavItem key={index}onClick={() => handleClick(route)}>
                  <Icon size={`1.8rem`} color={`white`} />
                  <Item> {label} </Item>
                </NavItem>
              )
            })
          }
        </Navigation>
      </Drawer>
    </Container>   
  )
}

export default Sidebar


const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 4rem;
  height: 100vh;
  left: 0;
  top:0;
  background-color: #363636;
  
  div:nth-child(even){
    
    &:hover{
      background-color: ${lightpurple};

    }
  }
  div:nth-child(odd){
    &:hover{
      background-color: ${lightblue};
    }
  }

  div:nth-child(1){

  }
`
const Container = styled.div`
 @media screen and (min-width: 768px) {
  display: none;
  }
  & > div > div > div {
    background-color: red;
  }
  height: 100vh;
`

const StyledDrawer = styled.div`
  background: #ff0000;
`
const NavItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: wrap;
  justify-content: flex-start;
  box-sizing:border-box;
  gap:10px;
  padding: .75rem;
  align-items: center;
  cursor: pointer;
 `
const IconBox = styled.div`
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  left:0px;
  top: 0px;
  flex-grow: 0;
`
const Item = styled.p`
  align-items: center;
  font-size: 1em;
  color: white;
  padding: 0px;
  margin: 2px;
`
