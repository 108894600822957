import { useState } from 'react'
import { Burger as MantineBurger } from '@mantine/core'

function Burger({ opened,setOpened }) {
  
  const title = opened ? `Close navigation` : `Open navigation`

  return (
    <MantineBurger
      size={25}
      opened={opened}
      onClick={() => setOpened(o => !o)}
      title={title}
      color="#363636"/>
  )
}

export default Burger