import moment from 'moment'
import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import { ArrowLeftCircle,ArrowRightCircle } from 'react-feather'
import { useUser } from '../hooks/useUser'
import { useTimes } from '../hooks/useTimes'
import { db } from '../Firebase'


const HourInput = ({ project,day,visibleArrows }) => {
  const { email } = useUser()
  const times = useTimes()

  const[userTime,setUserTime] = useState({})

  const handleChange = async value => {
    updateFirestore(Number(value)) 
  }

  const updateFirestore = async hour => {
    const ref = userTime?.id
      ? db.collection(`times`).doc(userTime.id)
      : db.collection(`times`).doc()
    await ref.set({ 
      date: day.toDate(),
      email,
      hours: hour || 0,
      projectId: project.id,
      invoiceId: null, 
    },{ merge: true })
  }

  useEffect(() => {
    const userTime = times.find(time => {
      return time.projectId === project.id
      && time.email === email
      && moment(time.date).format(`YYYY-MM-DD`) === moment(day).format(`YYYY-MM-DD`)})
    setUserTime(userTime)
  },[project,day,times])

  return (
    <Container> 
      <ButtonContainer visibility={visibleArrows} className="buttonContainer">
        <ArrowLeftCircle onClick={() => handleChange(Math.max((userTime?.hours || 0) - 1,0))} cursor="pointer"/>
      </ButtonContainer>
      <StyledInput 
        value={userTime?.hours || 0 } 
        type="number" 
        min="0" 
        onChange={e => handleChange(e.target.value)}/>
      <ButtonContainer visibility={visibleArrows} className="buttonContainer">
        <ArrowRightCircle onClick={() => handleChange((userTime?.hours || 0) + 1)} cursor="pointer"/>
      </ButtonContainer>
    </Container> 
  )
}

export default HourInput

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items:center;
  &:hover{
    .buttonContainer{
      visibility: visible;
    }
  }
`

const StyledInput = styled.input`
  max-width: 50px;
  justify-content:center;
  text-align: center;
  font-size: 1.3rem;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  &::-webkit-outer-spin-button,::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
`
const ButtonContainer = styled.div`
  display:flex;
  visibility: ${p => p.visibility ? `visible` : `hidden`};
  border: none;
  background-color: none !important;
`
