import React from 'react'
import { ArrowLeft,ArrowRight } from 'react-feather'
import styled from 'styled-components'
import { darkgray } from '../utils/colours'
import { formatMonth } from '../utils/formatter'
import moment from 'moment'
import Button from './button'
import { useMonth } from '../hooks/useMonth'

const MonthPicker = () => {
  const [month,setMonth] = useMonth()
  const handlePrevious = () => setMonth(moment(month).subtract(1,`M`).format(`YYYY-MM`))
  const handleNext = () => setMonth(moment(month).add(1,`M`).format(`YYYY-MM`))
  
  return (
    <Container>
      <Button onClick={handlePrevious} style={{ padding: `5px 10px` }}><ArrowLeft size={15}/></Button>
      <Month>{formatMonth(moment(month))}</Month>
      <Button onClick={handleNext} style={{ padding: `5px 10px` }}><ArrowRight size={15}/></Button>
    </Container>
  )
}

export default MonthPicker

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`
const Month = styled.div`
  color: ${darkgray};
  align-items: center;
  text-align: center;
  font-size: clamp(1rem, 1.5em, 2em);
  padding: 10px;
`