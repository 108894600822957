import { useHistory } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/auth'
import toast from 'react-hot-toast'
import { infoStyle } from '../utils/toast.format'
import { docSubscriptions } from '../hooks/useDoc'
import { colSubscriptions } from '../hooks/useCollection'

const Logout = () => {
  const history = useHistory()
  Object.keys(docSubscriptions).forEach(key => {
    docSubscriptions[key]?.()
  })
  Object.keys(colSubscriptions).forEach(key => {
    colSubscriptions[key]?.()
  })
  firebase.auth().signOut()
  // window.MessageBirdChatWidget.logout()
  toast(`Logged out`,infoStyle)
  history.push(`/`)
  return null
}

export default Logout