import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/performance'
import 'firebase/storage'
import { isObject } from './utils/isObject'
import { firebaseConfig } from './config'

firebase.initializeApp(firebaseConfig)
firebase.performance()

export const fb = firebase 
const database = fb.firestore()
export const db = database
export const { firestore } = firebase
export const { FieldValue } = firebase.firestore
export const functions = firebase.app().functions(`europe-west3`)
export const storage = firebase.app().storage(`gs://wakeflow-team.appspot.com`)
export const parseDocSnapshot = snapshot => {
  const { ref,...output } = { ...snapshot.data() }
  const recursivelyConvertTimestamps = thing => {
    if(!thing) return thing
    if(thing.seconds) return thing.seconds * 1000
    if(Array.isArray(thing)) return thing.map(t => recursivelyConvertTimestamps(t))
    if(isObject(thing)) Object.keys(thing).forEach(key => thing[key] = recursivelyConvertTimestamps(thing[key]))
    return thing
  }
  return {
    ...recursivelyConvertTimestamps(output),
    id: snapshot.id,
    path: snapshot.ref.path,
  }
}
export const parseCollectionSnapshot = snapshot => {
  const output = []
  snapshot.forEach(item => {
    output.push(parseDocSnapshot(item))
  })
  return output
}