import React from 'react'
import MainLayout from './mainLayout'
import styled from 'styled-components'
import MonthPicker from './monthPicker'
import Calendar from './calendar'
import ProjectPicker from './projectPicker'

const ThisMonth = () => {
  return(
    <MainLayout>
      <Container>
        <MonthPicker/>
        <ProjectPicker/>
        <Calendar/>
      </Container>
    </MainLayout>
  )
}

export default ThisMonth
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`

