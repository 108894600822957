import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import Spinner from './spinner'
import toast from 'react-hot-toast'
import { errorStyle } from '../utils/toast.format'
import { useUser } from '../hooks/useUser'
import { storage } from '../Firebase'


const SidebarProfilePicture = () => {
  const user = useUser()
  const storageRef = storage.ref()
  const [imageUrl,setImageUrl] = useState(``)
  const { profilePhoto } = user || {}

  useEffect(async() => {
    try{
      if(!profilePhoto) return setImageUrl(`https://robohash.org/${Math.floor(Math.random() * 20) + 1}?200x200`)
      const pathRef = storageRef.child(`${profilePhoto}`)
      const url = await pathRef.getDownloadURL()
      setImageUrl(url)
    }
    catch(error){
      toast(error.message,errorStyle)
    }
  },[profilePhoto])

  return (
    <Container>
      {!imageUrl && <Spinner />}
      {imageUrl && <Img alt="profile" src={`${imageUrl}`}/>}
    </Container>
  )
}

export default SidebarProfilePicture

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  flex-grow: 0 !important;
  transition: 0.1s all ease-in;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`
const Img = styled.img`
  width: 5rem;
  height: 5rem;
  padding: 10px;
  border-radius: 110px;
  object-fit: cover;
`