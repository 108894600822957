import React,{ useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import { darkgray,lightblue,lightgray,lightpurple } from '../utils/colours'
import ProfileItem from './profileItem'
import { useHistory } from 'react-router-dom'
import 'firebase/auth'
import Modal from './modal'
import Input from './input'
import Button from './button'
import { errorStyle } from '../utils/toast.format'
import { useCollection } from '../hooks/useCollection'
import { useUser } from '../hooks/useUser'
import { db } from '../Firebase'


const Employees = () => {
  const user = useUser()
  const users = useCollection(`users`) 
  const history = useHistory()
  const [show,setShow] = useState(false)
  const [email,setEmail] = useState(``)

  const handleClick = async() => {
    try{
      await db.doc(`users/${email}`).set({ email,firstName: `John`,lastName: `Smith`,role: `user`,jobTitle: `developer` },{ merge: true })
      history.push(`/employees/${email}`)
    }
    catch (error){
      console.log(error)
      toast(err.message,errorStyle)
    }
  }  
  return (
    <MainLayout>
      <Container>
        <Table>
          <thead>
            <tr>
              <Th> Name </Th>
              <Th> Email </Th>
              <Th> Contact </Th>
              <Th> Job Title </Th>
              {user.role === `admin` && <Th>Role</Th>}
            </tr>
          </thead>
          <Tbody>
            {users.map((user,index) => {
              return(
                <ProfileItem user={user} key={index}/>
              )
            })}
          </Tbody>
        </Table>
        {user.role === `admin` && <Button onClick={() => setShow(true)}>+ New Employee</Button>}
        <Modal 
          show={show}
          close={() => setShow(false)}>
          <ModalContainer>
            <span>Please enter the new user's email and temporary password:</span>
            <InputContainer> 
              <Input 
                label="Email"
                type="email"
                value={email}
                onChange={v => setEmail(v)}
                style={{ height: `3vh`,width: `5vw` }}/>
            </InputContainer>
            <Button onClick={handleClick}>Create</Button>
          </ModalContainer>
        </Modal>
      </Container>
    </MainLayout>
  )
}

export default Employees

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  color: ${darkgray};
  gap:20px;
  padding: 20px;
`

const Table = styled.table`
  display: table;
  flex-grow: 1;
  border-collapse: collapse;
  width: 60%;
  @media screen and (max-width: 600px){
    display: block;
    overflow-x: auto;
    width: 100%;
  }
`
const Th = styled.th`
  font-weight: 500;
  padding:20px;
  border-radius:0;
  text-align: center;
  border-bottom: 2px solid ${darkgray};
  background-color:white;
`
const Tbody = styled.tbody`
`
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`