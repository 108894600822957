import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { lightblue,lightpurple } from '../utils/colours'
import HourInput from './hourInput'

const DayProjectList = ({ day,projects }) => {
  const history = useHistory()
  const handleClick = async project => {
    history.push(`/project/${project.id}`)
  }
  return (
    <Container>
      {projects?.filter(p => p.status !== `closed`)
        .map((project,index) => {
          return (
            <Project key={index}>
              <ProjectName onClick={() => handleClick(project)}>{project?.name}</ProjectName>
              <HourInput day={day} project={project} visibleArrows={`visible`}/>
            </Project>
          )
        })}
    </Container>
      
  )
}

export default DayProjectList

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: clamp(300px,50vw,650px);
  overflow: auto;
  @media screen and (max-width: 600px){
  }
  & > div:nth-child(even){
    
    &:hover{
      background-color: ${lightpurple};
    }
  }
  & > div:nth-child(odd){
    &:hover{
      background-color: ${lightblue};
    }
  }

`
const Project = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius:20px;
`
const ProjectName = styled.div`
  font-weight: 600;
  width: 50%;
  justify-content: space-between;
  cursor: pointer;
`