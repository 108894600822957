import useHolidaysTaken from '../hooks/useHolidaysTaken.js'
import styled from 'styled-components'
import { useState } from 'react'
import { Group } from '@visx/group'
import { Pie } from '@visx/shape'
import { Text } from '@visx/text'
import { one,primary } from '../utils/colours.js'
import moment from 'moment'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js'

const HolidaysRemaining = () => {
  const { employeeId } = useParams()
  const [active,setActive] = useState(false)
  const daysTaken = useHolidaysTaken({ employeeId })
  const availableHolidayDays = daysTaken[moment().year()]?.allowance || 0
  const width = 150
  const half = width / 2 
  const daysTakenThisYear = daysTaken[moment().year()]?.taken || 0
  const data = [
    {
      label: `remaining`,
      value: availableHolidayDays - daysTakenThisYear,
      color: primary,
    },
    {
      label: `days taken`,
      value: daysTakenThisYear,
      color: one,
    },
    
  ]
 
  return (
    <Container>
      <svg width={width} height={width}>
        <Group top={half} left={half}>
          <Pie 
            data={data} 
            pieValue={data => data.value} 
            outerRadius={half} 
            innerRadius={({ data }) => {
              const size = active && active.label === data.label ? 10 : 8
              return half - size
                
            }} 
            padAngle={0.05}>
            {pie => {
              return pie.arcs.map(arc => {
                return <g 
                  key={arc.data.label} 
                  onMouseEnter={() => setActive(arc.data)}
                  onMouseLeave={() => setActive(null)}>
                  <path d={pie.path(arc)} fill={arc.data.color}></path>
                </g> 
              })
            }}
          </Pie>
          {active ? <>
            {/* Hover */}
            <Text
              textAnchor="middle"
              verticalAnchor="middle"
              fill="#363636" 
              fontSize={25}
              dy={-12}>
              {active.value}
            </Text>
            <Text
              style={{ fontWeight: `bold` }}
              textAnchor="middle"
              fill="#727272" 
              verticalAnchor="middle"
              fontSize={12}
              dy={8} >
              {active.label}
            </Text>
          </> : <>
            {/* Main */}
            <Text
              textAnchor="middle"
              fill="#363636" 
              verticalAnchor="middle" 
              fontSize={25}
              dy={-12}>
              {`${Math.floor(daysTakenThisYear)}/${availableHolidayDays}`}
            </Text>
            <Text
              style={{ fontWeight: `bold` }} 
              textAnchor="middle" 
              fill="#727272" 
              verticalAnchor="middle"
              fontSize={12}
              dy={8} >
              Holidays taken
            </Text></>
          }

        </Group>
      </svg>

      <div>
        {Object.keys(daysTaken).map(year => {
          return(
            <Row key={year}>
              <div>{year}:</div>
              <div>{daysTaken[year]?.taken}</div>
              <div>/</div>
              <div>{daysTaken[year].allowance}</div>
            </Row>
          )
        })}
      </div>
      
    </Container>
  )
}
export default HolidaysRemaining

const Container = styled.div`
  height: 150px;
  margin-bottom: 2rem;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width:100%;
`