import React from 'react'
import styled from 'styled-components'
import { darkgray,lightblue,lightgray,lightpurple } from '../utils/colours'
import CompletedGoalItem from './completedGoalItem'

const ArchivedGoalsList = ({ completedGoals }) => {
  return (
    <Table>
      <thead>
        <tr>
          <Th>Goal</Th>
          <Th>Started</Th>
          <Th>Finished</Th>
          <Th>Length</Th>
        </tr>
      </thead>
      <TBody>
        {completedGoals.map((completedGoal,index) => {
          return(<CompletedGoalItem key={index} completedGoal={completedGoal}/>)
        })}
          
      </TBody>
    </Table>
  )
}

export default ArchivedGoalsList

const Table = styled.table`
  display: table;
  border-collapse: collapse;
  width: clamp(200px,700px,85vw);
  padding: 20px;
  overflow-x: scroll;
`
const Th = styled.th`
  font-weight: 600;
  font-size: 1em;
  padding:10px;
  border-radius:0;
  text-align: left;
  border-bottom: 2px solid ${darkgray};
`
const TBody = styled.tbody`
  tr:nth-child(even){
    background-color: ${lightgray};
    &:hover{
      background-color: ${lightpurple};
    }
  }
  tr:nth-child(odd){
    &:hover{
      background-color: ${lightblue};
    }
  }
`