import styled from 'styled-components'

export const A = ({ href,children }) => {
  return (
    <Container href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </Container>
  )
}

const Container = styled.a`
`