import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { formatGoalDate } from '../utils/formatter'

const CompletedGoalItem = ({ completedGoal }) => {
  const dayDiff = moment(completedGoal?.completedAt).diff(moment(completedGoal?.createdAt),`day`)
  return (
    <Tr>
      <Td>
        {completedGoal.text}
      </Td>
      <Td>
        {formatGoalDate(completedGoal.createdAt)}
      </Td>
      <Td>
        {formatGoalDate(completedGoal.completedAt)}
      </Td>
      <Td>
        {dayDiff} days
      </Td>
    </Tr>
  )
}

export default CompletedGoalItem

const Tr = styled.tr``
const Td = styled.td`
  text-align: left;
  padding:10px;
  border-radius:0;
`