import { db,parseCollectionSnapshot,parseDocSnapshot } from '../Firebase'
import { atom,useAtom } from 'jotai'
import _ from 'lodash'

export const collectionsAtom = atom({})

export const colSubscriptions = {}

export const useCollection = name => {
  const [collections,setCollections] = useAtom(collectionsAtom)
  if(!name) return []
  if(colSubscriptions[name]) 
    return collections[name] || []
  colSubscriptions[name] = db.collection(name).onSnapshot(snapshot => {
    console.log(`---GOT COLLECTION SNAPSHOT---`)
    setCollections(s => {
      const output = _.cloneDeep(s)
      output[name] = parseCollectionSnapshot(snapshot)
      return output
    })
  })
  return collections[name] || []
}