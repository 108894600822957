import { useHolidayTimes } from './useHolidayTimes'
import { useEmployee } from './useEmployee.js'
import moment from 'moment'

const useHolidaysTaken = () => { 
  const employee = useEmployee()
  let times = useHolidayTimes()
  times = times.filter(({ email }) => email === employee.email)

  const { startDate } = employee
  const firstYear = moment(startDate).year()
  let currentYear = firstYear

  const summary = {}

  while(currentYear <= moment().year()){
    if(!summary[currentYear]) summary[currentYear] = {}
    if(!summary[currentYear].allowance) summary[currentYear].allowance = 0
    if(!summary[currentYear].taken) summary[currentYear].taken = 0
    if(currentYear === firstYear)
      summary[currentYear].allowance = Math.round(moment(startDate).endOf(`year`).diff(startDate,`days`) / 365 * 32)
    else
      summary[currentYear].allowance = 32 + Math.max(0,Math.min(5,summary[currentYear - 1].allowance - summary[currentYear - 1].taken))
    
    if(employee.email === `jamie@wakeflow.io` && currentYear === 2024) summary[currentYear].allowance = 28

    summary[currentYear].taken = times
      .filter(({ date }) => moment(date).year() === currentYear)
      .map(({ hours }) => hours)
      .reduce((acc,cur) => acc + cur,0) / 8

    if(employee.email === `aravind@wakeflow.io` && currentYear === 2021) summary[currentYear].taken = 27

    currentYear++
  }

  return summary
}

export default useHolidaysTaken